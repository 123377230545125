import { default as indext7dfob6oupMeta } from "/vercel/path0/pages/download/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexvrx28GESt5Meta } from "/vercel/path0/pages/policy/index.vue?macro=true";
import { default as index9OiDgfx7OcMeta } from "/vercel/path0/pages/terms/index.vue?macro=true";
export default [
  {
    name: "download",
    path: "/download",
    component: () => import("/vercel/path0/pages/download/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "policy",
    path: "/policy",
    meta: indexvrx28GESt5Meta || {},
    component: () => import("/vercel/path0/pages/policy/index.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: index9OiDgfx7OcMeta || {},
    component: () => import("/vercel/path0/pages/terms/index.vue")
  }
]