import revive_payload_client_I0CSxRFQhn from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.2.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_7ytzosz7e7dcjowwctimq66tpi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_niXghjVhx0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.2.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_7ytzosz7e7dcjowwctimq66tpi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_FIpKudoQGg from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.2.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_7ytzosz7e7dcjowwctimq66tpi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Mhk3YlY5iZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.2.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_7ytzosz7e7dcjowwctimq66tpi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Zg6wOktwny from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.2.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_7ytzosz7e7dcjowwctimq66tpi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_wM892P120C from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.2.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_7ytzosz7e7dcjowwctimq66tpi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_dwaiwXUXtx from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.2.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_7ytzosz7e7dcjowwctimq66tpi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_9eeuOxnPGy from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.2.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_7ytzosz7e7dcjowwctimq66tpi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_JbVdKNWcsu from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@8.33.1_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._hxqmtsug7lpmkbsc46essgnztu/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/.nuxt/sentry-client-config.mjs";
export default [
  revive_payload_client_I0CSxRFQhn,
  unhead_niXghjVhx0,
  router_FIpKudoQGg,
  payload_client_Mhk3YlY5iZ,
  navigation_repaint_client_Zg6wOktwny,
  check_outdated_build_client_wM892P120C,
  chunk_reload_client_dwaiwXUXtx,
  components_plugin_KR1HBZs4kY,
  prefetch_client_9eeuOxnPGy,
  sentry_client_JbVdKNWcsu,
  sentry_client_config_o34nk2sJbg
]